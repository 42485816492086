<template>
  <div class="wrapper">
    <div
      class="banner"
      data-aos="fade-down"
      data-aos-delay="300"
    >
      <div class="text">
        <h1
          data-aos="zoom-in-up"
          data-aos-delay="400"
        >
          {{ $t('alumnos.Consultor') }}
        </h1>
        <div
          class="hr"
          data-aos="zoom-in-up"
          data-aos-delay="500"
        />
        <h2
          data-aos="zoom-in-up"
          data-aos-delay="300"
        >
          {{ $t('alumnos.Para_alumnos') }}
        </h2>
        <b-button>
          <a href="tel:+34622094593">Whatsapp +34 622094593</a>
          <br>
          <a href="tel:+34617783460">tel +34 617783460</a>
        </b-button>
      </div>
    </div>
    <b-container
      lg="5"
      class="right-text mt-5 mb-5"
    >
      <h1
        data-aos="fade-down"
        data-aos-duration="500"
      >
        {{ $t('alumnos.PARA') }}
      </h1>
      <h1
        class=""
        data-aos="fade-down"
        data-aos-duration="300"
      >
        {{ $t('alumnos.EMPRESAS') }}
      </h1>
      <b-row>
        <b-col
          lg="5"
          md="5"
          sm="12"
        >
          <img
            loading="lazy"
            src="../../assets/img/alumnos/phone-using-png.png"
            alt="mobile-technology"
          >
        </b-col>
        <b-col
          lg="7"
          md="7"
          sm="12"
        >
          <h2 class="">
            {{ $t('alumnos.puedo') }}
          </h2>
          <p>
            {{ $t('alumnos.Estudiante') }}
          </p>
          <p>
            {{ $t('alumnos.Empresa') }}
          </p>
          <h3>
            {{ $t('alumnos.Enlace') }}
          </h3>
          <a href="/convenio-de-practicas/">https://www.websincoste.com/convenio-de-practicas/</a>
          <p>
            {{ $t('alumnos.Mi') }}
          </p>
        </b-col>
      </b-row>
    </b-container>
    <div
      id="muchas"
      class="section4"
    >
      <b-container>
        <h1
          data-aos="fade-down"
          data-aos-delay="300"
        >
          {{ $t('alumnos.PUBLICA') }}
        </h1>
        <h2
          data-aos="fade-down"
          data-aos-delay="400"
        >
          {{ $t('alumnos.oferta') }}
        </h2>
        <div
          class="white-box mt-4 mb-5"
          data-aos="fade-in"
          data-aos-delay="500"
        >
          <h1>{{ $t('alumnos.Consigue') }}</h1>
          <p class="p-def">
            {{ $t('alumnos.Sigue') }}
          </p>
          <b-button
            v-smooth-scroll="{ duration: 1500, offset: -100 }"
            class="btn"
            :to="{ path: '#cards' }"
          >
            {{ $t('alumnos.hazlo') }}
          </b-button>
        </div>
      </b-container>
    </div>
    <b-container
      id="cards"
      class="mt-5 mb-5"
    >
      <b-row>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h1>
              {{ $t('alumnos.Publica') }}
            </h1>
            <h2>
              {{ $t('alumnos.Si') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="200"
        >
          <div class="text-container">
            <h1>
              {{ $t('alumnos.Entrevista') }}
            </h1>
            <h2>
              {{ $t('alumnos.Col') }}
            </h2>
          </div>
        </b-col>
        <b-col
          lg="4"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          <div class="text-container">
            <h1>
              {{ $t('alumnos.Trámites') }}
            </h1>
            <h2>
              {{ $t('alumnos.Con') }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container
      class="section-black"
      data-aos="fade-right"
      data-aos-delay="300"
    >
      <b-row>
        <b-col
          lg="6"
          md="12"
        >
          <h1>{{ $t('alumnos.tiempo') }}</h1>
          <h2>{{ $t('alumnos.demora') }}</h2>
          <p>
            {{ $t('alumnos.AD') }}
          </p>
        </b-col>
        <b-col
          lg="6"
          md="12"
        >
          <img
            loading="lazy"
            src="../../assets/img/alumnos/web-development.png"
            class="youtube"
            alt="web-development"
          >
          <a
            href="https://planetajob.es/login"
            target="_blank"
            class="mt-2 d-block"
            rel="nofollow"
          >
            <b-button><span style="font-weight: bolder">{{ $t('alumnos.OFERTA') }}<br></span></b-button>
          </a>
        </b-col>
      </b-row>
    </b-container>
    <div class="cyan">
      <font-awesome-icon
        class="text-white"
        icon="caret-down"
        size="3x"
      />
      <h1 class="grey text-white">
        el candidato que necesitas
      </h1>
      <h2 class="h2-def text-white">
        de una forma más rápida, fácil y segura
      </h2>
      <font-awesome-icon
        class="text-white"
        icon="caret-up"
        size="3x"
      />
    </div>
    <div
      class="section8"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="800"
    >
      <h1>{{ $t('alumnos.Escucha') }}</h1>
      <b-container class="mt-5 mb-5">
        <b-row>
          <b-col
            lg="4"
            md="6"
            sm="12"
            data-aos="fade-down"
            data-aos-delay="200"
          >
            <iframe
              class="youtube"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/F4QOqU_zFbQ"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </b-col>
          <b-col
            lg="4"
            md="6"
            sm="12"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <iframe
              class="youtube"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/6X-BK6puwvc"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </b-col>
          <b-col
            lg="4"
            md="12"
            sm="12"
            data-aos="fade-down"
            data-aos-delay="400"
          >
            <iframe
              class="youtube"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/WkM5XiMGwq0"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
    name: 'PracicasAlumnos'
};
</script>

<style scoped lang="scss">
.wrapper {
	.banner {
		background-image: url('../../assets/img/alumnos/Banner-practicas-alumnos.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right;
		height: 800px;
		text-align: right;
		padding-right: 50px;
		padding-left: 50px;
		@media only screen and (max-width: 424px) {padding-right: 15px;
			padding-left: 15px;
			background-position: -500px !important;
		}

		.text {
			max-width: 500px;
			width: 100%;
			float: right;
			position: relative;
			top: 200px;

			h1 {
				font-family: LatoWebBlackItalic, sans-serif;
				font-size: 2.5em;
				color: white;
				@media only screen and (max-width: 424px) {
					font-size: 1.7em;
					color: white;
				}
			}

			.hr {
				height: 2px;
				width: 100%;
				background-color: white;
				margin-bottom: 10px;
			}

			h2 {
				font-family: LatoWebMedium, sans-serif;
				font-size: 1.5rem;
				color: white;
			}
		}
	}
	.btn {
		border: none;

		background-color: white;
		font-family: LatoWebMedium, sans-serif;
		width: 100%;
		color: #008bf2;
	}
	.grey {
		color: #a7a7a7;
		font-family: LatoWebBlack, sans-serif;
		font-size: 3rem;
		letter-spacing: 2px;
		@media only screen and (max-width: 424px) {
			font-size: 2rem;
		}
	}
	.grey-sub {
		color: #a9a9a9;
		font-family: LatoWebBlack, sans-serif;
		font-size: 2.5rem;
		letter-spacing: 2px;
		@media only screen and (max-width: 424px) {
			font-size: 1.2rem;
		}
	}
	.p-def {
		color: #0d0e0f;
		font-family: LatoWebLightItalic, sans-serif;
		font-size: 1.2rem;
		letter-spacing: 2px;
		@media only screen and (max-width: 424px) {
			font-size: 1rem;
		}
	}
	.h2-def {
		font-family: LatoWebLightItalic, sans-serif;
		font-size: 2.5rem;
		letter-spacing: 2px;
		@media only screen and (max-width: 424px) {
			font-size: 1.5rem;
		}
	}
	.right-text {
		max-width: 900px;
		img {
			width: 100%;
			max-width: 500px;
			display: block;
			margin: 0 auto;
		}
		.title {
			color: #009090;
			font-family: LatoWebBlack, sans-serif;
			font-size: 5rem;
			letter-spacing: 2px;
			@media only screen and (max-width: 424px) {
				font-size: 3rem;
			}
		}

		h2 {
			font-family: LatoWebLightItalic, sans-serif;
		}

		p {
			font-family: LatoWebLight, sans-serif;
			font-size: 1.5rem;
			color: #a7a7a7;
		}

		h3 {
			font-family: LatoWebBold, sans-serif;
			font-size: 1.5rem;
			color: #a7a7a7;
		}

		a {
			font-family: LatoWebBold, sans-serif;
			font-size: 1.2rem;
		}
	}
	.box-grey {
		-webkit-box-shadow: 20px 28px 48px 0px rgba(112, 112, 112, 1);
		-moz-box-shadow: 20px 28px 48px 0px rgba(112, 112, 112, 1);
		box-shadow: 20px 28px 48px 0px rgba(112, 112, 112, 1);

		padding: 80px 120px;
		margin: 0 auto;
		max-width: 900px;
		text-align: center;
		background: whitesmoke;
		@media only screen and (max-width: 424px) {
			padding: 20px 20px;
		}
	}
	.section4 {
		background-image: url('../../assets/img/convenio_practicas/background-girl.png');
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		height: auto;
		text-align: center;
		padding: 20px;
		@media only screen and (max-width: 424px) {
			padding: 50px 0 50px 0;
		}

		h1 {
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 6vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 5vh;
			}
		}

		h2 {
			align-content: center;
			font-family: LatoWebLight, sans-serif;
			font-size: 4vh;
			min-font-size: 500px;
			color: white;
		}

		h3 {
			padding-left: 100px;
			padding-right: 100px;
			text-align: left;
			font-family: LatoWebLight, sans-serif;
			font-size: 1.5vh;
			min-font-size: 500px;
			color: white;
			@media only screen and (max-width: 776px) {
				padding-left: 50px;
				padding-right: 50px;
				font-size: 2vh;
			}
			@media only screen and (max-width: 364px) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.white-box {
			text-align: center;
			width: 80%;
			height: 50%;
			margin: 0 auto;
			padding: 150px;
			border-top: #2a89b9 10px solid;
			background-color: whitesmoke;
			-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
			-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
			box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

			h1 {
				font-family: LatoWebBold, sans-serif;
				font-size: 2rem;
				color: #4b4b4b;
				margin-bottom: 20px;
				letter-spacing: 2px;
			}

			h2 {
				font-family: LatoWebLight, sans-serif;
				font-size: 1.2rem;
			}

			@media only screen and (max-width: 424px) {
				padding: 20px;
				margin-top: 0;
				width: 100%;
			}
			@media only screen and (max-width: 776px) {
				margin-top: 0;
				width: 100%;
			}
		}
	}
	.text-container {
		text-align: center;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 80px;
		border-top: #2a89b9 10px solid;
		background-color: white;
		-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

		h1 {
			font-family: LatoWebBlack, sans-serif;
			font-size: 1.5rem;
			color: #4b4b4b;
		}

		h2 {
			font-family: LatoWebLightItalic, sans-serif !important;
			font-size: 1.2rem;
		}

		@media only screen and (max-width: 424px) {
			padding: 50px;
			margin-top: 0;
			width: 100%;
		}
		@media only screen and (max-width: 776px) {
			margin-top: 0;
			width: 100%;
		}
	}
	.section-black {
		background: linear-gradient(90deg, rgba(0, 130, 195, 1) 0%, rgba(69, 187, 198, 1) 54%, rgba(197, 224, 195, 1) 100%);
		height: auto;
		padding: 100px;
		@media only screen and (max-width: 430px) {
			margin-top: -30px;
			padding: 80px 20px 20px 20px;
		}

		h1 {
			align-content: center;
			text-align: right;
			font-family: LatoWebBlack, sans-serif;
			font-size: 5vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 5vh;
			}
		}

		h2 {
			align-content: center;
			text-align: right;
			font-family: LatoWebMediumItalic, sans-serif;
			font-size: 2vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 3vh;
			}
		}
		p {
			align-content: center;
			text-align: left;
			font-family: LatoWebLight, sans-serif;
			font-size: 1.4rem;
			color: white;
		}
	}
	.youtube {
		width: 100%;
		position: relative;
		z-index: 51;
	}
	.cyan {
		text-align: center;
		background-color: #87ceeb;
	}
	.section8 {
		background: grey;
		background-position: center;
		background-size: cover;
		height: auto;
		text-align: center;
		padding: 50px 0;
		h1 {
			align-content: center;
			font-family: LatoWebBlack, sans-serif;
			font-size: 3vh;
			min-font-size: 500px;
			letter-spacing: 7px;
			color: white;
			@media only screen and (max-width: 430px) {
				font-size: 3px;
			}
		}
	}
}
</style>
